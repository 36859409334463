import ContactForm from './components/contact-form';
import Instagram from './components/instagram';
import Layout from './components/layout';

import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { servicesData } from '../graphql/services';

const Services = () => {
  const data = servicesData();
  return (
    <Layout>
      <Helmet>
        <title>Services - Petra Flucka Photography</title>
        <meta property="og:title" content="Services - Petra Flucka Photography" />
        <meta property="og:image" content={data.servicesImage.src} />
        <meta property="og:url" content="https://petraflucka.com/services/" />
        <meta
          property="og:description"
          content="What idea do you want to transmit? What impression are you trying to get across? Before any shots are taken, I work with you to understand your goals and dreams for the images. From there, I help you translate those desires into a creative direction, finding the right, props, displays, settings, and lighting to bring your vision to life."
        />
      </Helmet>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-24 gap-y-6 px-0 md:px-20 lg:px-40 xl:px-52">
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service1Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service1Text }} />
        </div>
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service2Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service2Text }} />
        </div>
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service3Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service3Text }} />
        </div>
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service4Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service4Text }} />
        </div>
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service5Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service5Text }} />
        </div>
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service6Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service6Text }} />
        </div>
        <div className="flex flex-col">
          <GatsbyImage image={{ ...data.service7Image.gatsbyImage }} alt="" />
          <div className="pt-4" dangerouslySetInnerHTML={{ __html: data.service7Text }} />
        </div>
      </div>
      <div className="px-0 md:px-20" dangerouslySetInnerHTML={{ __html: data.servicesText }} />
      <div className="md:flex md:space-x-10 px-0 md:px-20">
        <div className="w-full md:w-2/5 pb-4 md:pb-0">
          <GatsbyImage image={data.servicesImage.gatsbyImage} alt="" />
        </div>
        <ContactForm />
      </div>
      <Instagram />
    </Layout>
  );
};

export default Services;
