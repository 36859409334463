import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

import { Image } from '../types';

export const servicesData = (): {
  servicesImage: Image;
  service1Image: Image;
  service2Image: Image;
  service3Image: Image;
  service4Image: Image;
  service5Image: Image;
  service6Image: Image;
  service7Image: Image;
  servicesText: string;
  service1Text: string;
  service2Text: string;
  service3Text: string;
  service4Text: string;
  service5Text: string;
  service6Text: string;
  service7Text: string;
} => {
  const result = useStaticQuery(graphql`
    query {
      servicesImage: file(sourceInstanceName: { eq: "services" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 700)
        }
      }
      service1Image: file(sourceInstanceName: { eq: "service-1" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      service2Image: file(sourceInstanceName: { eq: "service-2" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      service3Image: file(sourceInstanceName: { eq: "service-3" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      service4Image: file(sourceInstanceName: { eq: "service-4" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      service5Image: file(sourceInstanceName: { eq: "service-5" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      service6Image: file(sourceInstanceName: { eq: "service-6" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      service7Image: file(sourceInstanceName: { eq: "service-7" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
      servicesText: file(sourceInstanceName: { eq: "services-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service1Text: file(sourceInstanceName: { eq: "service-1-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service2Text: file(sourceInstanceName: { eq: "service-2-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service3Text: file(sourceInstanceName: { eq: "service-3-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service4Text: file(sourceInstanceName: { eq: "service-4-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service5Text: file(sourceInstanceName: { eq: "service-5-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service6Text: file(sourceInstanceName: { eq: "service-6-text" }) {
        childMarkdownRemark {
          html
        }
      }
      service7Text: file(sourceInstanceName: { eq: "service-7-text" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  const servicesImage = {
    key: result.servicesImage.id,
    gatsbyImage: result.servicesImage.childImageSharp.gatsbyImageData,
    src: getSrc(result.servicesImage.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.servicesImage.name,
  };
  const service1Image = {
    key: result.service1Image.id,
    gatsbyImage: result.service1Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service1Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service1Image.name,
  };
  const service2Image = {
    key: result.service2Image.id,
    gatsbyImage: result.service2Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service2Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service2Image.name,
  };
  const service3Image = {
    key: result.service3Image.id,
    gatsbyImage: result.service3Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service3Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service3Image.name,
  };
  const service4Image = {
    key: result.service4Image.id,
    gatsbyImage: result.service4Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service4Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service4Image.name,
  };
  const service5Image = {
    key: result.service5Image.id,
    gatsbyImage: result.service5Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service5Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service5Image.name,
  };
  const service6Image = {
    key: result.service6Image.id,
    gatsbyImage: result.service6Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service6Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service6Image.name,
  };
  const service7Image = {
    key: result.service7Image.id,
    gatsbyImage: result.service7Image.childImageSharp.gatsbyImageData,
    src: getSrc(result.service7Image.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.service7Image.name,
  };
  const servicesText = result.servicesText.childMarkdownRemark.html;
  const service1Text = result.service1Text.childMarkdownRemark.html;
  const service2Text = result.service2Text.childMarkdownRemark.html;
  const service3Text = result.service3Text.childMarkdownRemark.html;
  const service4Text = result.service4Text.childMarkdownRemark.html;
  const service5Text = result.service5Text.childMarkdownRemark.html;
  const service6Text = result.service6Text.childMarkdownRemark.html;
  const service7Text = result.service7Text.childMarkdownRemark.html;
  return {
    servicesImage,
    service1Image,
    service2Image,
    service3Image,
    service4Image,
    service5Image,
    service6Image,
    service7Image,
    servicesText,
    service1Text,
    service2Text,
    service3Text,
    service4Text,
    service5Text,
    service6Text,
    service7Text,
  };
};
